import {Link} from "react-router-dom";
import React from "react";
import {ReactComponent as StatusIconDeliveredGreen} from "./../../../assets/StatusIconDeliveredGreen.svg";
import {ReactComponent as StatusIconDeliveredGray} from "./../../../assets/StatusIconDeliveredGray.svg";
import {ReactComponent as StatusIconServicedGreen} from "./../../../assets/StatusIconServicedGreen.svg";
import {ReactComponent as StatusIconServicedRed} from "./../../../assets/StatusIconServicedRed.svg";
import {ReactComponent as StatusIconServicedGray} from "./../../../assets/StatusIconServicedGray.svg";
import {ReactComponent as StatusIconSmsGreen} from "./../../../assets/StatusIconSmsGreen.svg";
import {ReactComponent as StatusIconSmsGray} from "./../../../assets/StatusIconSmsGray.svg";

const RepairStatusBar = ({repair}) => {
    const getServicedIcon = () => {
        if (!repair) return;
        if (repair.serviced === true) return <StatusIconServicedGreen/>;
        if (repair.serviced === false) return <StatusIconServicedRed/>;
        return <StatusIconServicedGray/>
    }

    const getGradientClass = () => {
        if (!repair) return;
        if (repair.sentSms && repair.serviced === false) return "from-red-to-green";
        if (repair.sentSms) return "active";
        if (!repair.sentSms) return "inactive";
        return <StatusIconServicedGray/>
    }

    return <div className={"status-bar flex items-center bg-white p-5 rounded-md w-1/2 justify-between relative "}>
        <div className={`serviced`}>
            {getServicedIcon()}
        </div>
        <div className={`sent-sms ${getGradientClass()}`}>
            {repair && repair.sentSms ? <StatusIconSmsGreen/> : <StatusIconSmsGray/>}
        </div>
        <div className={`delivered ${repair && repair.delivered ? "active" : "inactive"}`}>
            {repair && repair.delivered ? <StatusIconDeliveredGreen/> : <StatusIconDeliveredGray/>}
        </div>
    </div>
}

export default RepairStatusBar
