import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaLocationArrow, FaPlus, FaUser} from "react-icons/fa";
import {repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {TbLocation, TbLocationFilled} from "react-icons/tb";
import {IoPricetag} from "react-icons/io5";


const RepairTabContent = (props) => {
    return <div className={`main-tab bg-white w-full rounded-md ${!props.isSelected && "hidden"}`}>
        <div className={"p-5"}>
            <span className={"text-xl font-bold mb-2 block"}>{props.text}</span>
            {props.children}
        </div>
    </div>
}

export default RepairTabContent
