import React, {useEffect, useState} from "react";
import styles from "./Navbar.module.css";
import NavbarLink from "../NavbarLink/NavbarLink.js";
import {ReactComponent as LogoSvg} from "../../assets/LogoGreen.svg";
import {ReactComponent as OrdersSvg} from "../../assets/Orders.svg";
import {ReactComponent as OrdersActiveSvg} from "../../assets/OrdersGreen.svg";
import {ReactComponent as CustomersSvg} from "../../assets/Customers.svg";
import {ReactComponent as CustomersActiveSvg} from "../../assets/CustomersGreen.svg";
import {ReactComponent as RepairSvg} from "../../assets/Repair.svg";
import {ReactComponent as RepairActiveSvg} from "../../assets/RepairGreen.svg";
import {MdLogout} from "react-icons/md";
import {urlContains} from "../../utils/urlContains";
import {useTranslation} from "react-i18next";
import {userGetDetails} from "../../services/UserService";
import {CiSettings} from "react-icons/ci";
import {LiaHandshakeSolid} from "react-icons/lia";
import LanguageSelector from "../LanguageSelector/LanguageSelector";

function Navbar(props) {

    const {t, i18n} = useTranslation();
    const [user, setUser] = useState(false);

    const fetchUser = async ()=>{
        const userDetails = await userGetDetails()
        setUser(userDetails)
    }
    useEffect(async () => {
        fetchUser();
    }, []);

    const logout = () => {
        localStorage.setItem("accessToken", null)
        localStorage.setItem("refreshToken", null)
        window.location.href = "/login"
    }

    return (
        <div className={styles.navbar}>
            <div>
                <div className={styles.logo}>
                    <LogoSvg/>
                </div>

                <div className={styles.linkList}>
                    <span className={"font-bold block mb-4"}>{t("MENU")}</span>

                    {window.ORDER_MANAGEMENT_CUSTOMERS.includes(user.username) && <>
                        <NavbarLink
                            linkTo="/order/list"
                            icon={<OrdersSvg/>}
                            iconActive={<OrdersActiveSvg/>}
                            label={t("Orders")}
                            active={urlContains("order")}
                        />
                    </>}

                    <NavbarLink
                        linkTo="/repair/list"
                        icon={<RepairSvg/>}
                        iconActive={<RepairActiveSvg/>}
                        label={t("Repairs")}
                        active={urlContains("repair")}
                    />
                    <NavbarLink
                        linkTo="/customer/list"
                        icon={<CustomersSvg/>}
                        iconActive={<CustomersActiveSvg/>}
                        label={t("Customers")}
                        active={urlContains("customer")}
                    />
                    <NavbarLink
                        linkTo="/partner/list"
                        icon={<LiaHandshakeSolid color={"7C7C7C"}/>}
                        iconActive={<LiaHandshakeSolid color={"00BA98FF"}/>}
                        label={t("External Partners")}
                        active={urlContains("partner")}
                    />
                    <NavbarLink
                        linkTo="/settings"
                        icon={<CiSettings color={"7C7C7C"}/>}
                        iconActive={<CiSettings color={"00BA98FF"}/>}
                        label={t("Settings")}
                        active={urlContains("settings")}
                    />
                </div>
            </div>


            <div className={"w-full gap-2 flex flex-col"}>
                <LanguageSelector/>
                <button className={"flex items-center gap-4 cursor-pointer p-2"} onClick={logout}>
                    <MdLogout size={25} color={"#7C7C7C"}/>
                    <span className={styles.label}>{t("Log out")}</span>
                </button>
            </div>
        </div>
    );
}

export default Navbar;
