import axios from "axios";
import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function storeGetList() {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/stores`,
        })
    } catch (error) {
        throw error;
    }
}
