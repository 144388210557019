import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {customersGetList, customerUpdate} from "../../services/CustomerService";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {orderGetDetails, orderUpdate} from "../../services/OrderService";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";
import {userGetDetails} from "../../services/UserService";
import {SelectButton} from "primereact/selectbutton";
import {useTranslation} from "react-i18next";
import {storeConfigurationUpdate} from "../../services/StoreConfigurationService";

export default function Settings(props) {

    const [loading, setLoading] = useState(false);
    const toast = useRef(null);
    const [storeConfiguration, setStoreConfiguration] = useState(false);
    const {t, i18n} = useTranslation();

    const fetchData = async () => {
        try {
            setLoading(true)

            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)

            console.log(userDetails.store.configuration);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch user data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        try {

        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        window.location.href = "/order/list"
    }

    const updateStoreConfigurationAndReload = async (sc) => {
        try {
            const response = await storeConfigurationUpdate(sc);
        } catch (error) {
            alert("error");
            return
        }
        window.location.reload()
    }

    const updateAdminPanelPrintingType = async (e) => {
        let sc = storeConfiguration;
        sc.adminPanelPrintingType = e.value
        updateStoreConfigurationAndReload(sc)
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <Panel header={"Ρυθμίσεις"} className={"relative"}>
            <form onSubmit={(e) => {
                return formSubmit(e)
            }}>
                <FormControl label={t("Delivery Receipt Printing Type")} required={true}>
                    <Dropdown options={[
                        {label: t("No Printing"), value: "none"},
                        {label: "A4", value: "a4"},
                        {label: "A5", value: "a5"},
                    ]} optionLabel="label" value={storeConfiguration.adminPanelPrintingType}
                              onChange={updateAdminPanelPrintingType}/>
                </FormControl>
            </form>
            <LoadingAnimation state={loading}/>
        </Panel>
    </>

}
