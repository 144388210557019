import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {customersGetList} from "../../services/CustomerService";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {orderGetDetails, orderUpdate} from "../../services/OrderService";
import {Toast} from "primereact/toast";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";

export default function OrderEdit(props) {

    const {idOrder} = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(false);

    const toast = useRef(null);
    const [customers, setCustomers] = useState([]);
    const [description, setDescription] = useState("");
    const [paid, setPaid] = useState(false);
    const [price, setPrice] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);


    const fetchData = async () => {
        try {
            setLoading(true)

            // load customer
            const customersData = await customersGetList();
            customersData.content.map((customer) => {
                customer.template = customerOptionTemplate(customer)
            })
            setCustomers(customersData.content);

            // load order
            const orderData = await orderGetDetails(idOrder);
            setOrder(orderData);

            // fill form
            setSelectedCustomer(customersData.content.filter((c) => {
                return c.id = orderData.customer.id
            })[0])
            setPaid(orderData.paid)
            setDescription(orderData.description)
            setCompleted(orderData.completed)
            setPrice(orderData.price)

            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };


    useEffect(() => {
        fetchData();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault()

        try {
            await orderUpdate(idOrder, {
                "idCustomer": selectedCustomer.id,
                "description": description,
                "paid": paid,
                "completed": completed,
                "price": price,
            });
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        window.location.href = "/order/list"
    }

    const customerOptionTemplate = (customer) => {
        return `${customer.name} ${customer.companyName ? ` από ${customer.companyName}` : ""}` +
            `${customer.vatNumber ? ` (Α.Φ.Μ. ${customer.vatNumber})` : ""}`
    };
    const selectedCustomerTemplate = (customer, props) => {
        if (customer) {
            return customerOptionTemplate(customer)
        }

        return <span>{props.placeholder}</span>;
    };

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <Panel header={"Nέα Παραγγελία"} className={"relative"}>
            <form onSubmit={(e) => {
                return formSubmit(e)
            }}>
                <FormControl label={"Πελάτης"} required={true}>
                    <div className="card flex justify-content-center">
                        <Dropdown value={selectedCustomer} onChange={(e) => setSelectedCustomer(e.value)}
                                  options={customers} optionLabel="name" placeholder="Επιλέξτε πελάτη"
                                  filter filterBy={"template"} valueTemplate={selectedCustomerTemplate}
                                  itemTemplate={customerOptionTemplate} className="w-full md:w-14rem"/>
                    </div>
                </FormControl>
                <FormControl label={"Παραγγελία"} required={true}>
                    <div className="card flex justify-content-center">
                        <InputTextarea value={description} className={"w-full"}
                                       onChange={(e) => setDescription(e.target.value)}
                                       rows={5} cols={30}/>
                    </div>
                </FormControl>
                <FormControl label={"Ποσό παραγγελίας"} required={true}>
                    <div className="p-inputgroup flex-1 w-1/5">
                        <InputText placeholder="Ποσό παραγγελίας"
                                   onChange={(e) =>
                                       setPrice(e.target.value.replace(",", "."))}
                                   defaultValue={price}
                        />
                        <span className="p-inputgroup-addon">€</span>
                    </div>
                </FormControl>
                <div className="flex items-center mb-4 gap-10">
                    <div className="flex items-center gap-2">
                        <Checkbox inputId="completed" onChange={e => setCompleted(e.checked)}
                                  checked={completed}></Checkbox>
                        <label htmlFor="completed"
                               className="cursor-pointer text-base text-gray-500">Κατασκευάστηκε</label>
                    </div>
                    <div className="flex items-center gap-2">
                        <Checkbox inputId="paid" onChange={e => setPaid(e.checked)}
                                  checked={paid}></Checkbox>
                        <label htmlFor="paid" className="cursor-pointer text-base text-gray-500">Εξοφλήθη</label>
                    </div>
                </div>
                <div className={"flex justify-end"}>
                    <Button label="Ενημέρωση Παραγγελίας"/>
                </div>
            </form>
            <LoadingAnimation state={loading}/>
        </Panel>
    </>

}
