import {userIsAuthenticated} from "../../services/UserService";


const PrivateRoute = (props) => {

    const isAuthenticated = async () => {
        try {
            await userIsAuthenticated();
        } catch (error) {
            window.location.href = "/login"
        }
    }

    return (
        isAuthenticated() ? props.children : ""
    )

}

export default PrivateRoute
