import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as CurrentLocationIcon} from "./../../../assets/CurrentLocationIcon.svg";
import {ReactComponent as HistoryIcon} from "./../../../assets/HistoryIcon.svg";
import FormControl from "../../../components/UI/FormControl";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {repairTransferToStore, repairUpdate} from "../../../services/RepairService";
import RepairTransferToStore from "./RepairTransferToStore";
import RepairTransferToPartner from "./RepairTransferToPartner";
import formatDate from "../../../utils/formatDate";


const RepairLocationTabForm = ({repair, stores, partners, toast, reloadRepair}) => {
    const {t, i18n} = useTranslation();
    const [hasOnlyOneLocationInHistory, setHasOnlyOneLocationInHistory] = useState(false);

    useEffect(() => {
        if (!repair) return;
        setHasOnlyOneLocationInHistory(repair.history.length === 1)
    }, [repair]);

    const getLocationTypeText = (locationType) => {
        switch (locationType) {
            case "STORE":
                return t("Store")
            case "EXTERNAL_PARTNER":
                return t("External Partner")
        }
    }
    const getHistoryText = (historyItem) => {
        const isFirst = historyItem.dateAdd === repair.history[0].dateAdd
        switch (historyItem.locationType) {
            case "STORE":
                return (isFirst ? t("Registered in store ") : t("Transferred to store ")) + historyItem.location.alias
            case "EXTERNAL_PARTNER":
                return t("Transferred to external partner ") + historyItem.location.alias
        }
    }

    return <>
        <div className="flex gap-10 items-start">
            <div className="w-full">
                <FormControl label={t("Current Location")}>
                    <div className={"current-location w-full rounded-md"}>
                        <div className={"p-4 flex items-center gap-4"}>
                            <CurrentLocationIcon style={{width: "40px"}}/>
                            <div>
                                <span className={"block text-gray-500 text-sm"}>
                                    {repair && getLocationTypeText(repair.history[repair.history.length - 1].locationType)}
                                </span>
                                <span className={"block font-bold text-lg"}>
                                    {repair && repair.history[repair.history.length - 1].location.alias}
                                </span>
                            </div>
                        </div>
                    </div>
                </FormControl>

                <FormControl label={t("Transfer Repair")}>
                    <div className={"flex w-full gap-5"}>
                        <RepairTransferToStore repair={repair} toast={toast} stores={stores}
                                               reloadRepair={reloadRepair}/>
                        <RepairTransferToPartner repair={repair} toast={toast} partners={partners} stores={stores}
                                                 reloadRepair={reloadRepair}/>
                    </div>
                </FormControl>
            </div>

            <FormControl label={t("Location History")}>
                <div
                    className={`location-history flex ${!hasOnlyOneLocationInHistory && "flex-col"} justify-between relative`}>
                    {repair && !hasOnlyOneLocationInHistory && <div className={"horizontal-line"}></div>}

                    {repair && repair.history && repair.history.map((historyRecord) => {
                        return <div className={"location-history-item w-full rounded-md"}>
                            <div className={"flex items-center gap-4"}>
                                <HistoryIcon style={{width: "40px"}}/>
                                <div>
                                        <span className={"block text-md"}>
                                            {getHistoryText(historyRecord)}
                                        </span>
                                    <span className={"block text-gray-500 text-sm"}>
                                             {formatDate(historyRecord.dateAdd, true)}
                                        </span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </FormControl>
        </div>
    </>
}

export default RepairLocationTabForm
