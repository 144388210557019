import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaLocationArrow, FaPlus, FaUser} from "react-icons/fa";
import {repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {TbLocation, TbLocationFilled} from "react-icons/tb";
import {IoPricetag} from "react-icons/io5";
import {Checkbox} from "primereact/checkbox";
import FormControl from "../../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";


const RepairBillingTabForm = ({repair, setLoading, toast, reloadRepair}) => {
    const {t, i18n} = useTranslation();

    const [price, setPrice] = useState(null);
    const [payInAdvance, setPayInAdvance] = useState(null);
    const [cost, setCost] = useState(null);
    const [paid, setPaid] = useState(false);
    const [showPayInAdvance, setShowPayInAdvance] = useState(null);

    useEffect(() => {
        if (!repair) return;
        if (repair.price) setPrice(repair.price);
        if (repair.cost) setCost(repair.cost);
        if (repair.payInAdvanceAmount) {
            if (repair.payInAdvanceAmount < repair.price) {
                setShowPayInAdvance(true);
                setPayInAdvance(repair.payInAdvanceAmount);
            }else{
                setShowPayInAdvance(false);
                setPaid(true);
                setPayInAdvance(repair.payInAdvanceAmount);
            }
        }
        if (repair.paid) setPaid(repair.paid);
    }, [repair]);

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true);
        try {
            await repairUpdate(repair.id, null, null, null, null,
                null, parseFloat(price), parseFloat(payInAdvance), [], null, cost);

        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        setLoading(false);
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    return <form>
        <div className={"flex gap-5"}>
            <FormControl label={t("Repair amount")}>
                <div className="p-inputgroup flex-1 ">
                    <InputText placeholder={t("Repair amount")} keyfilter="money" value={price}
                               onChange={(e) => {
                                   setPrice(e.target.value.replace(",", "."))
                               }}
                    />
                    <span className="p-inputgroup-addon">€</span>
                </div>
            </FormControl>
            <FormControl label={t("Pay In Advance Amount / Inspection Cost")}
                         className={payInAdvance === 0 && "invisible"}>
                <div className="p-inputgroup flex-1 ">
                    <InputText placeholder={t("Pay In Advance Amount / Inspection Cost")}
                               keyfilter="money" value={payInAdvance}
                               onChange={(e) => {
                                   setPayInAdvance(e.target.value.replace(",", "."))
                               }}
                    />
                    <span className="p-inputgroup-addon">€</span>
                </div>
            </FormControl>
        </div>

        <div className="flex items-center mb-4 gap-10">
            <div className="flex items-center gap-2">
                <Checkbox inputId="payinadvance" onChange={e => {
                    setShowPayInAdvance(e.checked)
                    if (e.checked) {
                        setPaid(false)
                        setPayInAdvance(null)
                    } else {
                        setPaid(false)
                        setPayInAdvance(0)
                    }
                }}
                          checked={showPayInAdvance}></Checkbox>
                <label htmlFor="payinadvance"
                       className="cursor-pointer text-base text-gray-500">{t("Pay In Advance / Inspection Cost")}</label>
            </div>
            <div className="flex items-center gap-2">
                <Checkbox inputId="paid" onChange={e => {
                    setPaid(e.checked)
                    if (e.checked) {
                        setShowPayInAdvance(false)
                        setPayInAdvance(price != null ? price : 1)
                    } else {
                        setShowPayInAdvance(false)
                        setPayInAdvance(0)
                    }
                }}
                          checked={paid}></Checkbox>
                <label htmlFor="paid"
                       className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
            </div>
        </div>
        <div className={"w-1/2 "}>
            <FormControl label={t("Repair cost")}>
                <div className="p-inputgroup flex-1">
                    <InputText placeholder={t("Repair cost")} value={cost}
                               onChange={(e) =>
                                   setCost(e.target.value.replace(",", "."))}
                    />
                    <span className="p-inputgroup-addon">€</span>
                </div>
            </FormControl>
        </div>

        <div className={"text-right "}>
            <Button type="submit" icon="pi pi-save" className={"text-lg π-"} label={t("Save")} onClick={formSubmit}/>
        </div>
    </form>
}

export default RepairBillingTabForm
