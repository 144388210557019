import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaLocationArrow, FaPlus, FaUser} from "react-icons/fa";
import {repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {TbLocation, TbLocationFilled} from "react-icons/tb";
import {IoPricetag} from "react-icons/io5";
import FormControl from "../../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";


const RepairTabForm = ({repair, toast, setLoading}) => {
    const {t, i18n} = useTranslation();

    const [description, setDescription] = useState(null);
    const [type, setType] = useState(null);
    const [serialNumber, setSerialNumber] = useState(null);

    useEffect(() => {
        if (!repair) return;
        if (repair.description) setDescription(repair.description);
        if (repair.type) setType(repair.type);
        if (repair.serialNumber) setSerialNumber(repair.serialNumber);
    }, [repair]);

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true);
        try {
            await repairUpdate(repair.id, null, null, serialNumber, description,
                null, null, null, [], type);

        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατά την δημιουργία του πελάτη',
                life: 3000,
                position: "bottom"
            });
            return
        }

        setLoading(false);
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });

    }

    return <form>
        <div className={"flex gap-5"}>
            <FormControl label={t("Repair Type")}>
                <div className="card flex justify-content-center">
                    <InputText placeholder={t("Repair Type")} className={"w-full"} defaultValue={type}
                               onChange={(e) =>
                                   setType(e.target.value)}
                    />
                </div>
            </FormControl>
            <FormControl label={t("Serial Number")}>
                <div className="card flex justify-content-center">
                    <InputText placeholder={t("Serial Number")} className={"w-full"}
                               defaultValue={serialNumber}
                               onChange={(e) =>
                                   setSerialNumber(e.target.value)}
                    />
                </div>
            </FormControl>
        </div>
        <FormControl label={t("Description")} required={true}>
            <div className="card flex justify-content-center">
                <InputTextarea defaultValue={description} className={"w-full"}
                               placeholder={t("Description")}
                               onChange={(e) => setDescription(e.target.value)}
                               rows={10} cols={50}/>
            </div>
        </FormControl>
        <div className={"text-right "}>
            <Button type="submit" icon="pi pi-save" className={"text-lg π-"} label={t("Save")} onClick={formSubmit}/>
        </div>
    </form>
}

export default RepairTabForm
