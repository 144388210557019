import React from "react";
import styles from "./NavbarLink.module.css";
import {Link} from "react-router-dom";

function NavbarLink(props) {
    return (
        <Link
            className={`${styles.link}  ${props.active ? styles.active : ""}`}
            key={Math.random(0, 999999)}
            to={props.linkTo}
        >
            <div className={styles.iconContainer}>
                {props.active ? props.iconActive : props.icon}
            </div>
            <span className={styles.label}>{props.label}</span>
        </Link>
    );
}

export default NavbarLink;
