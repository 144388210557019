import {useEffect, useState} from "react"
import {ReactComponent as ErrorSvg} from "../../../assets/ErrorSvg.svg";
import styles from "./ErrorMessage.module.css"
import Validate from "../../../utils/Validate";
import {useTranslation} from "react-i18next";

export default function ErrorMessage(params) {
    const { t, i18n } = useTranslation();

    const [isHidden, setIsHidden] = useState(true)
    useEffect(()=>{
        setIsHidden(Validate.isEmpty(params.message))
    },[params.message])

    if(Validate.isEmpty(params.message)){
        return;
    }

    return (
        <div className={`${styles.container} ${isHidden ? styles.hidden : "" }`}>
            <ErrorSvg/>
            <div className={styles.innerContainer}>
                <h2 className={"text-xl"}>{t("Error")}</h2>
                <p>{params.message}</p>
            </div>
        </div>
    )
}

