import styles from "./MainLayout.module.css"
import {BreadCrumb} from "primereact/breadcrumb";
import {HiHome} from "react-icons/hi";
import Navbar from "../../components/NavBar/Navbar";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";
import React from "react";
import {Link} from "react-router-dom";

export default function MainLayout(props) {

    const getBreadcrumbLinks = ()=>{
        const breadcrumbs = []
        props.breadcrumb.map((b)=> {
            breadcrumbs.push({
                label:b.label,
                template: () => <Link to={b.url} style={{color:"#7C7C7C"}}>{b.label}</Link>
            })
        })
        return breadcrumbs;
    }

    return (
        <>
            <div className={"w-full flex "}>
                <Navbar language={props.language}  setLanguage={props.setLanguage}/>
                <div className={`bg-zinc-200 relative h-screen overflow-auto ${styles.mainContainer}`}>
                    <div className={"p-10"}>
                        <div className={"flex items-center w-full justify-between mb-5"}>
                            <BreadCrumb className={" bg-transparent p-0"} model={getBreadcrumbLinks()}
                                        home={{icon: <HiHome color={"#7C7C7C"}/>, url: "/"}}/>

                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
}
