import {useRef, useState} from "react";
import Validate from "../../../../utils/Validate";
import {userLogin} from "../../../../services/UserService";
import Input from "../../../../components/UI/Input/Input";
import ErrorMessage from "../../../../components/UI/ErrorMessage/ErrorMessage";
import {ReactComponent as UserInputIcon} from "../../../../assets/UserInputIcon.svg";
import {ReactComponent as PasswordInputIcon} from "../../../../assets/PasswordInputIcon.svg";
import {Button, ButtonVariation} from "../../../../components/UI/Button/Button";
import {useTranslation} from "react-i18next";


export default function LoginForm(props) {

    const usernameRef = useRef();
    const passwordRef = useRef();
    const buttonRef = useRef();
    const {t, i18n} = useTranslation();
    const [errorMessage, setErrorMessage] = useState(null)

    const login = async (e) => {

        e.preventDefault()

        const username = usernameRef.current.getValue();
        const password = passwordRef.current.getValue();

        // Validation
        if (Validate.isEmpty(username)) return setErrorMessage(t("Please enter your username"))
        if (Validate.isEmpty(password)) return setErrorMessage(t("Please enter your password"))

        // open loading animation
        props.setLoadingAnimationState(true);

        // Try to login
        let responseData
        try {
            const response = await userLogin(username, password)
            responseData = response.data
        } catch (error) {
            props.setLoadingAnimationState(false);
            return setErrorMessage(t("The username or password is incorrect"))
        }
        if (Validate.isEmpty(responseData)) {
            props.setLoadingAnimationState(false);
            return setErrorMessage(t("Something went wrong"))
        }

        // Save tokens to localstorage
        localStorage.setItem("accessToken", responseData.accessToken)
        localStorage.setItem("refreshToken", responseData.refreshToken)

        // redirect
        if (responseData.user.company.name === "Dinerly") {
            window.location.href = "/printing-test"
        } else if (window.ORDER_MANAGEMENT_CUSTOMERS.includes(responseData.user.username)) {
            window.location.href = "/order/list"
        } else {
            window.location.href = "/repair/list"
        }
    }

    return (
        <div className={"w-full"}>

            <h1 className={"text-white text-4xl font-semibold text-center mb-16"}>{t("Log in")}</h1>

            <form onSubmit={login}>
                <Input
                    ref={usernameRef}
                    IconComponent={UserInputIcon}
                    label={t("Username")}
                    type={"text"}
                />

                <Input
                    ref={passwordRef}
                    IconComponent={PasswordInputIcon}
                    label={t("Password")}
                    type={"password"}
                />

                <ErrorMessage
                    message={errorMessage}
                />

                <Button
                    variation={ButtonVariation.WHITE}
                    ref={buttonRef}
                    text={t("Log in")}
                />
            </form>

        </div>
    )
}
