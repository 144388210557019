import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaLocationArrow, FaPlus, FaUser} from "react-icons/fa";
import {repairTransferToStore, repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {TbLocation, TbLocationFilled} from "react-icons/tb";
import {IoPricetag} from "react-icons/io5";
import {Dialog} from "primereact/dialog";
import FormControl from "../../../components/UI/FormControl";
import {Dropdown} from "primereact/dropdown";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {ReactComponent as TransferToStoreIcon} from "./../../../assets/TransferToStore.svg";


const RepairTransferToStore = ({repair, stores, toast, reloadRepair}) => {

    const {t, i18n} = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedStore, setSelectedStore] = useState(false);
    const [transferComments, setTransferComments] = useState("");
    const [isStoreInPartner, setIsStoreInPartner] = useState(false);

    useEffect(() => {
        if (!stores) return;
        let alreadyChosenSelected = false;
        stores.map((store) => {
            const disabled = store.id === repair.history[repair.history.length - 1].location.id
            if (!disabled && !alreadyChosenSelected) {
                setSelectedStore({
                    "storeDesc": `${store.alias} (${store.address})`,
                    "id": store.id,
                    "disabled": disabled,
                });
                alreadyChosenSelected = true;
            }
        })
        setIsStoreInPartner(repair.history[repair.history.length - 1].locationType === "EXTERNAL_PARTNER");
    }, [stores]);


    if (stores && stores.length === 1 && !isStoreInPartner) {
        return;
    }

    const transferRepairToStore = async () => {
        try {
            await repairTransferToStore(repair.id, selectedStore.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new order"),
                life: 3000,
                position: "bottom"
            });
            return
        }

        setIsModalVisible(false)
        reloadRepair()
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair updated successfully'),
            life: 3000
        });
    }

    const modalFooterContent = (
        <div>
            <Button label={t("Cancel")} icon="pi pi-times" onClick={() => setIsModalVisible(false)}
                    className="p-button-text"/>
            <Button label={t("Complete")} icon="pi pi-check" onClick={transferRepairToStore} autoFocus/>
        </div>
    );

    const getStoreOptions = () => {
        if (!stores) return [];
        let storeOptions = [];
        stores.map((store) => {
            const disabled = store.id === repair.history[repair.history.length - 1].location.id
            storeOptions.push({
                "storeDesc": `${store.alias} (${store.address})`,
                "id": store.id,
                "disabled": disabled,
            })
        })
        return storeOptions
    }

    return <>
        <button className={`transfer-to-store-button w-full p-5 rounded-md text-white flex` +
            ` ${!isStoreInPartner ? "flex-col" : "justify-center"} items-center gap-3`}
                onClick={() => {
                    setIsModalVisible(true)
                }}
        >
            <TransferToStoreIcon/>
            {isStoreInPartner ? t("Return to Store") : t("Transfer to another Store")}
        </button>
        <Dialog header={isStoreInPartner ? t("Return repair to Store") : t("Transfer repair to another Store")} visible={isModalVisible} style={{width: '50vw'}}
                onHide={() => {
                    if (!isModalVisible) return;
                    setIsModalVisible(false);
                }} footer={modalFooterContent}>
            <FormControl label={t("Store")}>
                <Dropdown options={getStoreOptions()} optionLabel="storeDesc" value={selectedStore} onChange={(s) => {
                    setSelectedStore(s.value)
                }} valueTemplate={(s) => {
                    if (!s) return "";
                    return s.storeDesc
                }}/>
            </FormControl>
            <FormControl label={t("Comments")}>
                <InputTextarea value={transferComments} className={"w-full"}
                               onChange={(e) => setTransferComments(e.target.value)}
                               rows={5} cols={30}/>
            </FormControl>
        </Dialog></>
}

export default RepairTransferToStore
