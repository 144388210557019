import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your language JSON files
import enTranslation from './localization/en.json';
import elTranslation from './localization/el.json';

i18n
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes i18n instance to react-i18next
    .init({
        resources: {
            en: {
                translation: enTranslation,
            },
            el: {
                translation: elTranslation,
            },
        },
        fallbackLng: 'en', // Default language if no match
        debug: true,
        interpolation: {
            escapeValue: false, // React already escapes values by default
        },
    });

export default i18n;
