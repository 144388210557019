import {Link} from "react-router-dom";
import React, {useRef, useState} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaLocationArrow, FaPlus, FaUser} from "react-icons/fa";
import {repairUpdate} from "../../../services/RepairService";
import {useTranslation} from "react-i18next";

import {ReactComponent as ServiceIcon} from "./../../../assets/RepairFilled.svg";
import {TbLocation, TbLocationFilled} from "react-icons/tb";
import {IoPricetag} from "react-icons/io5";


const RepairTab = ({text, icon, isSelected, callback}) => {
    return <>
        <button className={`repair-tab flex items-center p-3 gap-5 rounded-md w-full justify-center` +
            ` ${isSelected ? "selected" : "bg-white"}`}
                onClick={() => {
                    callback()
                }}
        >
            {icon}
            <span className={"text-lg"}>{text}</span>
        </button>
    </>
}

export default RepairTab
