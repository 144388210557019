import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function loadProtectedImage(imageUrl) {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: imageUrl,
            responseType: 'blob', // Important: This tells Axios to download the response as a Blob
        });

        // Create a local URL for the blob
        return URL.createObjectURL(response);
    } catch (error) {
        throw error;
    }
}

export async function imageCreate(image) {
    const formData = new FormData();
    formData.append("image", image);

    try {
        const response = await callApiWithAuthentication({
            method: "POST",
            url: `${window.API_URL}/v2/image`,
            data: formData, // Use 'data' here for the form body in Axios
        });
        return response;
    } catch (error) {
        throw error;
    }
}
