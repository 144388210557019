import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {useEffect, useRef, useState} from "react";
import {customerDelete, customersGetList} from "../../services/CustomerService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export default function CustomerList(props) {

    const toast = useRef(null);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);

    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sort, setSort] = useState("id,desc");
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const fetchCustomers = async () => {
        setLoading(true)
        try {
            const customersPage = await customersGetList(page, size, sort);
            setCustomers(customersPage.content);
            setTotalRecords(customersPage.totalElements);
            setLoading(false);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά κατα την φόρτωση των πελατών',
                life: 3000,
                position: "bottom"
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, [page, sort, size]);

    const deleteCustomer = async (customer) => {
        setLoading(true)
        try {
            await customerDelete(customer.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: 'Κάτι πήγε στραβά',
                detail: 'Κάτι πήγε στραβά',
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: 'Διαγράφηκε',
            detail: 'Ο πελάτης διαγράφηκε με επιτυχία',
            life: 3000
        });
        fetchCustomers()
    }

    const closeModal = () => {
    }

    const confirmDelete = (customer) => {
        confirmDialog({
            message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε αυτόν τον πελάτη;',
            header: 'Διαγραφή πελάτη',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                deleteCustomer(customer)
            },
            reject: () => {
                closeModal()
            }
        });
    }

    const getHeader = () => {
        return <div className={"flex items-center justify-between w-full"}>
            <span>Πελάτες</span>
            <Button icon={<PlusIcon color={"white"} className={"mr-2"}/>} onClick={() => {
                navigate("/customer/create")
            }} label={"Νέος Πελάτης"}/>
        </div>
    }

    const getActionsButtons = (customer) => {
        return <>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltip={"Επεξεργασία πελάτη"}
                    tooltipOptions={{position: 'bottom'}} icon={<AiFillEdit color={"grey"} onClick={() => {
                navigate(`/customer/${customer.id}/edit`)
            }
            }/>}/>
            <Button className={"bg-transparent border-0 p-1 w-auto "} tooltipOptions={{position: 'bottom'}}
                    onClick={() => {
                        confirmDelete(customer)
                    }}
                    tooltip={"Διαγραφή πελάτη"} icon={<TrashIcon color={"grey"}/>}/>
        </>
    }
    const onPage = (e) => {
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setSort(`${e.sortField},${e.sortOrder === 1 ? "desc" : "asc"}`)
    }


    const showBulkOptions = (e) => {
        return <Button
            outlined disabled={selectedCustomers.length === 0} label="Διαγραφή" icon="pi pi-times"
            onClick={() => {
                confirmDialog({
                    message: 'Έιστε σίγουρος πως θέλετε να διαγράψετε τους επιλεγμένους πελάτες;',
                    header: 'Διαγραφή επιλεγμένων πελάτών',
                    icon: 'pi pi-info-circle',
                    defaultFocus: 'reject',
                    acceptClassName: 'p-button-primary',
                    acceptLabel: t("Yes"),
                    rejectLabel: t("No"),
                    accept: () => {
                        selectedCustomers.map((customer) => {
                            deleteCustomer(customer)
                        })
                    },
                    reject: () => {
                        closeModal()
                    }
                });
            }}/>
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <Panel header={getHeader()}>
            <DataTable
                value={customers} showGridlines selectionMode={'checkbox'} selection={selectedCustomers}
                onSelectionChange={(e) => setSelectedCustomers(e.value)}
                stripedRows className={"w-full"} size={"small"} paginator
                totalRecords={totalRecords} lazy loading={loading} onSort={onSort}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Σύνολο: {totalRecords} Πελάτες"
                first={first} onPage={onPage} rows={size} pageCount={5} rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage={"Δεν βρέθηκαν πελάτες"}
                dataKey="id" paginatorLeft={showBulkOptions()}
            >
                <Column selectionMode="multiple" headerStyle={{width: '3rem'}}></Column>
                <Column field="name" sortable header="Όνομα"/>
                <Column field="phoneNumber1" sortable header="Τηλεφωνο"></Column>
                <Column field="companyName" sortable header="Επωνυμία Εταιρείας"></Column>
                <Column field="vatNumber" sortable header="Α.Φ.Μ."></Column>
                <Column field="address" sortable header="Διεύθυνση" body={(customer) => {
                    return `${customer.address} ${customer.city} ${customer.postCode}`
                }}></Column>
                <Column field="actions" header="Ενέργειες" body={getActionsButtons}/>
            </DataTable>
        </Panel>
    </>;
}
