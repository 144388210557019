import React, {useEffect, useRef, useState} from 'react';
import {Image} from 'primereact/image';
import {ProgressSpinner} from 'primereact/progressspinner';
import {loadProtectedImage} from "../services/ImageService";
import ImageWithAuth from "./ImageWithAuth";
import {LuEye} from "react-icons/lu";

const ThumbnailImageWithAuth = ({imageUrl, hasPreview = true}) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const imageRef = useRef(null);

    const fetchImage = async () => {
        setLoading(true);
        setError(null);
        try {
            setImageSrc(await loadProtectedImage(imageUrl));
        } catch (error) {
            setError("Failed to load image");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchImage();

        // Cleanup the object URL when component unmounts
        return () => {
            if (imageSrc) {
                URL.revokeObjectURL(imageSrc);
            }
        };
    }, [imageUrl]);

    if (loading) return <div className={"flex items-center justify-center aspect-square"}>
        <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="4" stroke={"red"} animationDuration="1s"/>
    </div>;
    if (error) return <p>{error}</p>;

    return <div
        className={"thumbnail-image rounded-md bg-gray-200 aspect-square relative overflow-hidden service-item-image bg-cover bg-center"}
        style={{backgroundImage: `url(${imageSrc})`}}>
        {hasPreview && <>
            <div className={"eye absolute top-0 left-0 right-0 bottom-0  flex items-center justify-center"}
                 onClick={() => {
                     imageRef.current.show();
                 }}>
                <LuEye color={"white"} size={30}/>
            </div>
            <Image src={imageSrc} alt="Image" width="250" preview ref={imageRef} className={"primereact-image-hidden"}/>
        </>}
    </div>
};

export default ThumbnailImageWithAuth;
