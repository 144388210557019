import {Link} from "react-router-dom";
import ThumbnailImageWithAuth from "../../../components/ThumbnailImageWithAuth";
import {Tag} from "primereact/tag";
import formatPrice from "../../../utils/formatPrice";
import formatDate from "../../../utils/formatDate";
import React, {useEffect, useRef, useState} from "react";
import LoadingAnimation from "../../../components/UI/LoadingAnimation/LoadingAnimation";

export default function RepairGridLayout(props) {

    const scrollableRef = useRef(null);
    const [is700pxFromBottom, setIs700pxFromBottom] = useState(false);

    const checkScrollPosition = () => {
        const scrollableElement = scrollableRef.current;
        if (!scrollableElement) return;

        const scrollTop = scrollableElement.scrollTop; // Current scroll position from top
        const scrollHeight = scrollableElement.scrollHeight; // Total content height
        const clientHeight = scrollableElement.clientHeight; // Visible height

        // Check if we're less than 700px from the bottom
        const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
        setIs700pxFromBottom(distanceFromBottom <= 700);
    };

    useEffect(() => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
            scrollableElement.addEventListener('scroll', checkScrollPosition);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', checkScrollPosition);
            }
        };
    }, []);

    useEffect(() => {
        if (!is700pxFromBottom) return;
        props.loadNextPage()
    }, [is700pxFromBottom]);


    return <div>
        {/*<div className={"flex items-center justify-between"}>*/}
        {/*    <div>Search</div>*/}
        {/*    <div className={"flex items-center gap-5"}>*/}
        {/*        <div>Sort</div>*/}
        {/*        <div>Filters</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        <div className={"repair-grid-container flex flex-wrap gap-5 w-full overflow-auto"} ref={scrollableRef}>
            {props.repairs.map((repair) => {
                return <Link to={`/repair/${repair.id}/edit`} className={"repair bg-white rounded-md relative"}>
                    <div className={"w-full repair-image-container aspect-square rounded-t-md"}>
                        {repair && repair.images && repair.images[0] &&
                            <ThumbnailImageWithAuth imageUrl={repair.images[0].url} hasPreview={false}/>
                        }
                    </div>
                    <div className={"p-5 mb-12"}>
                        <p className={"color-black font-bold"}>{repair && repair.customer.name}</p>
                        <p className={"text-gray-500 text-sm"}>{repair && repair.description}</p>
                        <div className={"flex items-center justify-between absolute bottom-5 left-5 right-5"}>
                            <Tag value={repair.code}/>
                            <div>
                                <p className={"color-black font-bold text-right"}>{repair && formatPrice(repair.price)}</p>
                                <p className={"text-gray-400 text-xs"}>{formatDate(repair.dateAdd)}</p>
                            </div>
                        </div>
                    </div>
                </Link>
            })}

            {/*Skeletons */}
            {props.loading && (
                <>
                    {Array.from({length: 20}).map((_, index) => (
                        <div key={index} className="repair rounded-md relative skeleton-repair ">
                            <LoadingAnimation state={true}/>
                        </div>
                    ))}
                </>
            )}

        </div>
    </div>
}
