import {IoListSharp} from "react-icons/io5";
import {LuLayoutGrid} from "react-icons/lu";
import styles from "./LayoutSelector.module.css";

export default function LayoutSelector(props) {
    return <div className={`flex items-center  rounded-md ${styles.container}`}>
        <div className={`flex items-center justify-center p-3 rounded-md ${styles.icon} 
            ${["default","list"].includes(props.layout) ? styles.selected : ""}`}  onClick={()=>props.setLayout("list")}
        >
            <IoListSharp color={"#7C7C7C"} size={20}/>
        </div>
        <div className={`flex items-center justify-center p-3 rounded-md ${styles.icon} 
            ${props.layout === "grid" ? styles.selected : ""}`} onClick={()=>props.setLayout("grid")}
        >
            <LuLayoutGrid color={"#7C7C7C"} size={20}/>
        </div>
    </div>
}
