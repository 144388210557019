import {useState} from "react";
import LoginForm from "./components/LoginForm/LoginForm";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import LoadingAnimation from "../../components/UI/LoadingAnimation/LoadingAnimation";

export default function Login() {
    const [loadingAnimationState, setLoadingAnimationState] = useState(false)

    return (<>
        <div className={"login-page"}>
            <AuthLayout>
                <LoginForm setLoadingAnimationState={setLoadingAnimationState}/>
            </AuthLayout>
            <LoadingAnimation state={loadingAnimationState}/>
        </div>
    </>);
}
