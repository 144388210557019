import {Link} from "react-router-dom";
import React, {useRef} from "react";
import {ReactComponent as UserIcon} from "./../../../assets/UserIcon.svg"
import ImageWithAuth from "../../../components/ImageWithAuth";
import {FaPlus} from "react-icons/fa";
import {repairUpdate} from "../../../services/RepairService";
import ThumbnailImageWithAuth from "../../../components/ThumbnailImageWithAuth";

const RepairImages = ({repair, toast, setLoading, reloadRepair}) => {

    const fileInputRef = useRef(null);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            await uploadFile(file); // Call uploadFile function with the selected file
        }
    };

    // Function to upload file to the backend
    const uploadFile = async (image) => {
        try {
            setLoading(true);
            const response = await repairUpdate(
                repair.id, // ID of the repair item
                null, // Other fields are null since we're only updating images
                null, null, null, null, null, null,
                [image] // Passing the image in an array
            );

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Image uploaded successfully',
                life: 3000
            });
            reloadRepair()
        } catch (error) {
            toast.current.show({
                severity: 'error',
                summary: 'Upload Error',
                detail: 'Failed to upload image',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    return <div className={"repair-edit-images bg-white p-5 rounded-md flex items-center flex-wrap service-item-images"}>
        {repair && repair.images && repair.images.map((image) => {
            return <ThumbnailImageWithAuth imageUrl={image.url}/>
        })}
        <div
            className={"rounded-md bg-gray-200 aspect-square flex items-center justify-center cursor-pointer service-item-image"}
            onClick={() => {
                fileInputRef.current.click();
            }}>
            <input
                type="file"
                ref={fileInputRef}
                style={{display: 'none'}}
                onChange={handleFileChange}
            />
            <FaPlus className={"text-xl text-gray-500"}/>
        </div>
    </div>
}

export default RepairImages
