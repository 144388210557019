import axios from "axios";
import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";

export async function userLogin(username, password) {
    return axios({
        method: "post",
        url: `${window.API_URL}/auth/login?username=${username}&password=${password}`,
    });
}

export async function userIsAuthenticated() {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/user`,
        })
    } catch (error) {
        throw error;
    }
}

export async function userGetDetails() {
    try {
        return await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/user`,
        })
    } catch (error) {
        throw error;
    }
}
