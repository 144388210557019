import "./styles/fonts.css";
import "./styles/variables.css";
import "./App.css";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login/Login";
import PrivateRoute from "./routing/PrivateRoute/PrivateRoute";
import {addLocale, locale, PrimeReactProvider} from "primereact/api";
import OrderCreate from "./pages/OrderCreate/OrderCreate";
import CustomerCreate from "./pages/CustomerCreate/CustomerCreate";
import CustomerList from "./pages/CustomerList/CustomerList";
import CustomerEdit from "./pages/CustomerEdit/CustomerEdit";
import "./styles/primereact.css";
import 'primeicons/primeicons.css';
import "./styles/primereact-overrides.css";
import OrderList from "./pages/OrderList/OrderList";
import OrderEdit from "./pages/OrderEdit/OrderEdit";
import OrderShippingNote from "./pages/OrderShippingNote/OrderShippingNote";
import CloudPrintingTest from "./pages/CloudPrintingTest/CloudPrintingTest";
import MainLayout from "./layouts/MainLayout/MainLayout";
import {useTranslation} from "react-i18next";
import RepairList from "./pages/RepairList/RepairList";
import RepairCreate from "./pages/RepairCreate/RepairCreate";
import RepairEdit from "./pages/RepairEdit/RepairEdit";
import RepairDeliveryReceipt from "./pages/RepairDeliveryReceipt/RepairDeliveryReceipt";
import Settings from "./pages/Settings/Settings";
import RepairDeliveryReceiptA5 from "./pages/RepairDeliveryReceiptA5/RepairDeliveryReceiptA5";
import PartnerList from "./pages/PartnerList/PartnerList";
import PartnerCreate from "./pages/PartnerCreate/PartnerCreate";
import PartnerEdit from "./pages/PartnerEdit/PartnerEdit";
import {useState} from "react";
import RepairLayout from "./layouts/RepairLayout/RepairLayout";

addLocale('el', {
    startsWith: "Αρχίζει με",
    contains: "Περιέχει",
    notContains: "Δεν περιέχει",
    endsWith: "Τελειώνει με",
    equals: "Ισούται",
    notEquals: "Δεν ισούται",
    noFilter: "Χωρίς φίλτρο",
    lt: "Μικρότερο από",
    lte: "Μικρότερο ή ίσο",
    gt: "Μεγαλύτερο από",
    gte: "Μεγαλύτερο ή ίσο",
    is: "Είναι",
    isNot: "Δεν είναι",
    before: "Πριν",
    after: "Μετά",
    dateIs: "Η ημερομηνία είναι",
    dateIsNot: "Η ημερομηνία δεν είναι",
    dateBefore: "Η ημερομηνία είναι πριν",
    dateAfter: "Η ημερομηνία είναι μετά",
    clear: "Εκκαθάριση",
    apply: "Εφαρμογή",
    matchAll: "Ταιριάζει με όλα",
    matchAny: "Ταιριάζει με οποιοδήποτε",
    addRule: "Προσθήκη κανόνα",
    removeRule: "Αφαίρεση Κανόνα",
    accept: "Ναι",
    reject: "Όχι",
    choose: "Επέλεξε",
    upload: "Μεταφόρτωση",
    cancel: "Ακύρωση",
    dayNames: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"],
    dayNamesShort: ["Κυρ", "Δευ", "Τρι", "Τετ", "Πεμ", "Παρ", "Σαβ"],
    dayNamesMin: ["Κυ", "Δε", "Tρ", "Τε", "Πε", "Πα", "Σα"],
    monthNames: ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"],
    monthNamesShort: ["Ιαν", "Φεβ", "Μαρ", "Απρ", "Μαϊ", "Ιουν", "Ιουλ", "Αυγ", "Σεπ", "Οκτ", "Νοε", "Δεκ"],
    dateFormat: "mm/dd/yy",
    firstDayOfWeek: 0,
    today: "Σήμερα",
    weekHeader: "Εβδ",
    weak: "Εβδομάδα",
    medium: "Μέτριο",
    strong: "Ισχυρό",
    passwordPrompt: "Παρακαλώ εισάγετε τον κωδικό ασφαλείας",
    emptyMessage: "Δεν βρέθηκαν αποτελέσματα",
    emptyFilterMessage: "Δεν βρέθηκαν αποτελέσματα"
});
locale('el');

function App() {

    const {t, i18n} = useTranslation();

    return (
        <PrimeReactProvider value={{unstyled: false, pt: {}, ptOptions: {mergeSections: true, mergeProps: true}}}>
            <Router>
                <div className="App">
                    <Routes>
                        <Route exact path="/login" element={<Login/>}></Route>
                        <Route exact path="/order/create" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Orders"), url: "/order/list"},
                                    {label: t("New Order"), url: "/order/create"},
                                ]}>
                                    <OrderCreate/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/list" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Orders"), url: "/order/list"},
                                ]}>
                                    <OrderList/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/:idOrder/edit" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Orders"), url: "/order/list"},
                                    {label: t("Edit Order")},
                                ]}>
                                    <OrderEdit/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/order/:idOrder/shipping-note" element={
                            <PrivateRoute>
                                <OrderShippingNote/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/create" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Customers"), url: "/customer/list"},
                                    {label: t("New Customer"), url: "/customer/create"},
                                ]}>
                                    <CustomerCreate/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/list" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Customers"), url: "/customer/list"},
                                ]}>
                                    <CustomerList/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/customer/:idCustomer/edit" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Customers"), url: "/customer/list"},
                                    {label: t("Customer Edit")},
                                ]}>
                                    <CustomerEdit/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/printing-test" element={
                            <PrivateRoute>
                                <CloudPrintingTest breadcrumb={[
                                    {label: t("Cloud Printing Test")}
                                ]}/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/partner/list" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("External Partners"), url: "/partner/list"},
                                ]}>
                                    <PartnerList/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/partner/create" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("External Partners"), url: "/partner/list"},
                                    {label: t("New Partner"), url: "/partner/create"},
                                ]}>
                                    <PartnerCreate/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/partner/:idPartner/edit" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("External Partners"), url: "/partner/list"},
                                    {label: t("Edit Partner")},
                                ]}>
                                    <PartnerEdit/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/repair/list" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Repairs"), url: "/repair/list"},
                                ]}>
                                    <RepairList/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/repair/create" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Repairs"), url: "/repair/list"},
                                    {label: t("New Repair"), url: "/repair/create"},
                                ]}>
                                    <RepairCreate/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/repair/:idRepair/edit" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Repairs"), url: "/repair/list"},
                                    {label: t("Edit Repair")},
                                ]}>
                                    <RepairEdit/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/repair/:idRepair/delivery-receipt" element={
                            <PrivateRoute>
                                <RepairDeliveryReceipt/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/repair/:idRepair/delivery-receipt-a5" element={
                            <PrivateRoute>
                                <RepairDeliveryReceiptA5/>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route exact path="/settings" element={
                            <PrivateRoute>
                                <MainLayout breadcrumb={[
                                    {label: t("Settings"), url: "/settings"},
                                ]}>
                                    <Settings/>
                                </MainLayout>
                            </PrivateRoute>
                        }>
                        </Route>
                        <Route path="*" element={<Navigate to="/login" replace/>}/>
                    </Routes>
                </div>
            </Router>
        </PrimeReactProvider>
    );
}

export default App;
