import classes from "./AuthLayout.module.css";
import {ReactComponent as LoginVector} from "./../../assets/LoginVector.svg";
import {ReactComponent as LoginVectorGear1} from "./../../assets/LoginVectorGear1.svg";
import {ReactComponent as LoginVectorGear2} from "./../../assets/LoginVectorGear2.svg";
import {ReactComponent as LoginVectorGear3} from "./../../assets/LoginVectorGear3.svg";
import {ReactComponent as LogoSvg} from "./../../assets/LogoSvg.svg";

export default function AuthLayout(props) {
    return <>
        <div className={classes.container}>
            <div className={classes.left}>
                <LogoSvg className={classes.logoSvg}/>
                {props.children}
            </div>
            <div className={classes.right}>
                <div className={classes.vectorContainer}>
                    <LoginVectorGear1 className={classes.LogoSvg}/>
                    <LoginVectorGear2 className={classes.loginVectorGear2}/>
                    <LoginVectorGear3 className={classes.loginVectorGear3}/>
                    <LoginVector className={classes.loginVector}/>
                </div>
            </div>
        </div>
    </>
}
