import axios from "axios";
import {refreshToken} from "../services/AuthService";

export const callApiWithAuthentication = async (apiCall) => {
    try {
        return await makeCall(apiCall)
    } catch (error) {
        if (error.response.status === 403) {
            await refreshToken()
            return await makeCall(apiCall)
        }
    }
}

const makeCall = async (apiCall) => {
    apiCall.headers = {
        ...apiCall.headers,
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    };

    const axiosConfig = {
        method: apiCall.method,
        url: apiCall.url,
        headers: apiCall.headers,
        data: apiCall.data,
        responseType: apiCall.responseType || "json", // Default to 'json'
    };

    const response = await axios(axiosConfig);
    return response.data;
}
