import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useReactToPrint} from 'react-to-print';
import LoadingAnimation from '../../components/UI/LoadingAnimation/LoadingAnimation';
import {userGetDetails} from '../../services/UserService';
import {loadProtectedImage} from '../../services/ImageService';
import {useTranslation} from "react-i18next";
import {repairGetDetails} from "../../services/RepairService";
import formatDate from "../../utils/formatDate";

const RepairDeliveryReceiptA5 = () => {
    const {idRepair} = useParams();
    const [repair, setRepair] = useState(null);
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const componentRef = useRef();
    const {t, i18n} = useTranslation();

    const fetchData = async () => {
        setLoading(true)
        try {
            const [userDetails, repairDetails] = await Promise.all([
                userGetDetails(),
                repairGetDetails(idRepair),
            ]);
            setImageUrl(await loadProtectedImage(userDetails.image.url));
            setRepair(repairDetails);
            setUser(userDetails);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch customer data:', error);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (repair) {
            handlePrint();
        }
    }, [repair]);


    const getTermsOfUse = () => {
        if (!user) {
            return "";
        }
        if (user.company.termsOfUse) {
            return user.company.termsOfUse;
        }
        return `ΜΕ ΤΗΝ ΠΑΡΑΛΑΒΗ ΑΥΤΟΥ ΤΟΥ ΑΠΟΚΟΜΜΑΤΟΣ, ΕΞΟΥΣΙΟΔΟΤΩ ΤΗΝ ΕΤΑΙΡΕΙΑ ${user.company.name}
                ΚΑΙ ΤΟΥΣ ΤΕΧΝΙΚΟΥΣ ΤΗΣ ΝΑ ΠΡΟΧΩΡΗΣΟΥΝ ΣΤΗΝ ΕΠΙΣΚΕΥΗ ΤΩΝ ΠΡΟΪΟΝΤΩΝ ΠΟΥ ΑΝΑΓΡΑΦΟΝΤΑΙ ΠΑΡΑΠΑΝΩ. ΔΕΝ
                ΕΠΙΡΡΙΠΤΩ ΟΥΔΕΜΙΑ ΕΥΘΥΝΗ ΣΤΗΝ ΕΤΑΙΡΕΙΑ ${user.company.name} ΚΑΙ ΓΙΑ ΟΠΟΙΑΔΗΠΟΤΕ ΑΛΛΗ ΒΛΑΒΗ ΜΠΟΡΕΙ ΝΑ
                ΔΗΜΙΟΥΡΓΗΘΕΙ ΣΤΟ ΠΡΟΪΟΝ ΜΟΥ ΣΤΟ ΜΕΛΛΟΝ ΕΚΤΟΣ ΕΑΝ ΟΦΕΙΛΕΤΑΙ ΚΑΠΟΙΟ ΑΠΟ ΤΑ ΑΝΤΑΛΛΑΚΤΙΚΑ ΤΑ ΟΠΟΙΑ
                ΑΝΤΙΚΑΤΑΣΤΑΘΗΚΑΝ ΚΑΤΑ ΤΗ ΔΙΑΡΚΕΙΑ ΤΗΣ ΕΠΙΣΚΕΥΗΣ ΠΟΥ ΕΓΙΝΕ. Ο ΧΡΌΝΟΣ ΕΠΙΣΚΕΥΉΣ ΕΞΑΡΤΆΤΑΙ ΑΠΌ ΤΗΝ
                ΔΙΑΘΕΣΙΜΌΤΗΤΑ ΤΟΥ ΑΝΤΑΛΛΑΚΤΙΚΟΎ ΤΟΥ ΕΚΆΣΤΟΤΕ ΚΑΤΑΣΚΕΥΑΣΤΉ, ΑΛΛΆ ΚΑΙ ΑΠΡΌΟΠΤΩΝ ΕΠΙΠΛΟΚΏΝ ΚΑΤΆ ΤΗ
                ΔΙΑΔΙΚΑΣΊΑ ΑΠΟΚΑΤΆΣΤΑΣΗΣ. Η ΕΤΑΙΡΕΊΑ ${user.company.name} ΔΕN ΦΈΡΕΙ ΚΑΜΊΑ ΕΥΘΎΝΗ ΓΙΑ ΟΠΟΙΑΔΉΠΟΤΕ
                ΚΑΘΥΣΤΈΡΗΣΗ ΚΑΘΏΣ ΚΑΙ ΓΙΑ ΤΙΣ ΣΥΝΈΠΕΙΕΣ ΠΟΥ ΑΥΤΉ ΜΠΟΡΕΊ ΝΑ ΈΧΕΙ ΓΙΑ ΤΟΝ ΠΕΛΆΤΗ. ΟΙ ΣΥΣΚΕΥΈΣ ΜΕΤΆ
                ΤΗΝ ΟΛΟΚΛΉΡΩΣΗ ΤΗΣ ΕΠΙΣΚΕΥΉΣ ΘΑ ΚΡΑΤΙΟΎΝΤΑΙ ΣΤΟ ΚΑΤΆΣΤΗΜΑ ΓΙΑ 30 ΗΜΈΡΕΣ ΜΕΤΆ ΤΗΝ ΕΙΔΟΠΟΊΗΣΗ ΣΤΟΝ
                ΠΕΛΆΤΗ ΓΙΑ ΠΑΡΑΛΑΒΉ ΜΕΤΆ ΤΟ ΠΈΡΑΣ ΤΩΝ ΗΜΕΡΏΝ ΤΟ ΚΑΤΆΣΤΗΜΑ ΈΧΕΙ ΚΆΘΕ ΝΌΜΙΜΟ ΔΙΚΑΊΩΜΑ ΝΑ
                ΚΑΤΑΣΤΡΈΨΕΙ ΚΑΙ ΝΑ ΣΤΕΊΛΕΙ ΚΑΙ ΣΥΣΚΕΥΈΣ ΓΙΑ ΑΝΑΚΎΚΛΩΣΗ`
    }

    const getCompanyLegalEntity = () => {
        if (!user) {
            return "";
        }
        if (user.store.legalEntity) {
            return <>
                <b>{user.store.legalEntity.name}</b>
                <p>{user.store.legalEntity.address}</p>
                <p>{user.store.legalEntity.occupation}</p>
                <p>ΑΦΜ: {user.store.legalEntity.vatNumber}</p>
                <p>ΤΗΛ: {user.store.legalEntity.telephone}</p>
                <p>ΔΟΥ: {user.store.legalEntity.doy}</p>
                <p>ΓΕΜΗ: {user.store.legalEntity.gemh}</p>
            </>
        }
        return <>
            <b>{user.company.name}</b>
            <p>{user.store.address}</p>
            <p>{user.phoneNumber}</p>
        </>
    }

    return (
        <div>
            <div ref={componentRef} style={{
                fontSize: "11px"
            }}>
                <div style={{padding: "20px", fontSize: "15px",}}>
                    <table style={{marginBottom: '10px', width: '100%', fontSize: "9px"}}>
                        <tbody>
                        <tr>
                            <td style={{width: "180px", paddingRight: "20px"}}>
                                {imageUrl != null && <img style={{maxWidth: "100%"}} src={imageUrl} alt="User"/>}
                            </td>
                            <td>{getCompanyLegalEntity()}</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className={"my-3"}>
                        <b className={"block text-left "}>ΔΕΛΤΙΟ ΠΟΣΟΤΙΚΗΣ ΠΑΡΑΛΑΒΗΣ ΕΠΙΣΚΕΥΗΣ
                            #{repair && repair.code}</b>
                        <p className={"text-left mb-1"}>{formatDate(repair && repair.dateAdd,true)}</p>
                    </div>
                    <div className={"flex gap-5 w-full"}>
                        <div className={"bg-gray-200 px-3 py-2 rounded mb-4 w-full"}>
                            <b className={"mb-2"}>Στοχεία πελάτη</b>
                            <p>{repair && repair.customer.name}</p>
                            <p>
                                {repair && repair.customer.phoneNumber1}
                                {repair && repair.customer.phoneNumber2 && ` - ${repair.customer.phoneNumber2}`}
                            </p>
                        </div>
                        <div className={"bg-gray-200 px-3 py-2 rounded mb-4 w-full"}>
                            <b className={"mb-2"}>Περιγραφή Επισκευής</b>
                            {repair && repair.type ? <p>{repair.type}</p> : ""}
                            {repair && repair.serialNumber ? <p>S/N: {repair.serialNumber}</p> : ""}
                            <p>{repair && repair.description}</p>
                        </div>
                    </div>
                    <div className={"bg-gray-200 px-3 py-2 rounded mb-4 flex"}>
                        <div className={"w-full"}>
                            <b className={"mb-2"}>Κόστος Επισκευής</b>
                            <p>{(repair != null && repair.price != 0) ? repair.price.toFixed(2) + "€" : "ΔΕΝ ΟΡΙΣΤΗΚΕ"}</p>
                        </div>
                        {repair && repair.payInAdvanceAmount && <div className={"w-full"}>
                            <b className={"mb-2"}>Προκαταβολή / Ελεγχος</b>
                            <p>{(repair != null && repair.payInAdvanceAmount != 0) ? repair.payInAdvanceAmount.toFixed(2) + "€" : "ΔΕΝ ΟΡΙΣΤΗΚΕ"}</p>
                        </div>}
                    </div>
                </div>

                <div className={"pr-10 text-right pt-8"}>
                    <span className={"text-xs block"}>Υπογραφή Πελάτη</span>
                    <span className={"text-xs block"}>{formatDate(repair && repair.dateAdd,true)}</span>
                </div>
                <div style={{padding: "20px"}}>
                    <p>ΟΡΟΙ ΧΡΗΣΗΣ</p>
                    <p style={{fontSize: "5px"}}>{getTermsOfUse()}</p>
                </div>
            </div>
            <LoadingAnimation state={loading}/>
        </div>
    );
}

export default RepairDeliveryReceiptA5;
