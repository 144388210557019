import axios from "axios";
import {callApiWithAuthentication} from "../utils/callApiWithAuthentication";


export const partnersGetList = async (page = 0) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/v2/external-partners/?page=${page+1}`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export async function partnerCreate(requestBody) {
    try {
        const response = await callApiWithAuthentication({
            method: "post",
            url: `${window.API_URL}/external-partner/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
}


export const partnerDelete = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "delete",
            url: `${window.API_URL}/external-partner/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};


export const partnerGetDetails = async (id) => {
    try {
        const response = await callApiWithAuthentication({
            method: "get",
            url: `${window.API_URL}/external-partner/${id}/`
        });
        return response
    } catch (error) {
        throw error;
    }
};

export const partnerUpdate = async (id, requestBody) => {
    try {
        const response = await callApiWithAuthentication({
            method: "put",
            url: `${window.API_URL}/external-partner/${id}/`,
            data: requestBody
        });
        return response
    } catch (error) {
        throw error;
    }
};
