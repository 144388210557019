import axios from "axios";

export const refreshToken = async () => {
    try {
        const response = await axios({
            method: "post",
            url: `${window.API_URL}/auth/refresh-token`,
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("refreshToken")}`
            }
        });
        localStorage.setItem("accessToken", response.data.accessToken)
        localStorage.setItem("refreshToken", response.data.refreshToken)
    } catch (error) {
        throw error;
    }
}
