import {Panel} from "primereact/panel";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import FormControl from "../../components/UI/FormControl";
import {customerCreate} from "../../services/CustomerService";
import {useTranslation} from "react-i18next";
import {partnerCreate} from "../../services/PartnerService";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function PartnerCreate(props) {

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();
    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);
    const [floor, setFloor] = useState(null);
    const [hours, setHours] = useState(null);
    const [loading, setLoading] = useState(false);

    const formSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const response = await partnerCreate({
                "alias": name, "address": address, "floor": floor, "hours": hours,
            });
        } catch (error) {
            alert("error");
            setLoading(false)
            return
        }

        setLoading(false)
        navigate("/partner/list")
    }

    return <Panel header={t("New Partner")}>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-4"}>
                <FormControl label={t("Name")} required={true}>
                    <InputText placeholder={t("Name")} name={"name"} value={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                </FormControl>
                <FormControl label={t("Address")} required={true}>
                    <InputText placeholder={t("Address")} name={"address"} value={address}
                               onChange={(e) => {
                                   setAddress(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex gap-4"}>
                <FormControl label={t("Floor")}>
                    <InputText placeholder={t("Floor")} name={"floor"} value={floor}
                               onChange={(e) => {
                                   setFloor(e.target.value)
                               }}/>
                </FormControl>
                <FormControl label={t("Ηours of operation")}>
                    <InputText placeholder={t("Ηours of operation")} name={"hours"} value={hours}
                               onChange={(e) => {
                                   setHours(e.target.value)
                               }}/>
                </FormControl>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Partner")} loading={loading}/>
            </div>
        </form>
    </Panel>
}
