import {Link, redirect, useNavigate} from "react-router-dom";
import React from "react";
import {ReactComponent as CallIcon} from "./../../../assets/Call.svg";
import {ReactComponent as DeleteIcon} from "./../../../assets/Delete.svg";
import {ReactComponent as DeliverIcon} from "./../../../assets/Deliver.svg";
import {ReactComponent as PrintIcon} from "./../../../assets/Print.svg";
import {ReactComponent as SendSMSIcon} from "./../../../assets/SendSMS.svg";
import {ReactComponent as ServiceIcon} from "./../../../assets/Service.svg";
import {ReactComponent as UnServiceIcon} from "./../../../assets/UnService.svg";
import {useTranslation} from "react-i18next";
import {confirmDialog} from "primereact/confirmdialog";
import {
    repairDelete, repairMarkAsDelivered, repairMarkAsNotDelivered,
    repairMarkAsServiced, repairMarkServiceFalse,
    repairMarkServiceNull,
    repairSendSMS
} from "../../../services/RepairService";
import {Button} from "primereact/button";
import {SplitButton} from "primereact/splitbutton";
import {IoThumbsDownSharp} from "react-icons/io5";
import {IoMdClose} from "react-icons/io";

const RepairActions = ({repair, setLoading, toast, reloadRepair}) => {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const deleteRepair = async () => {
        setLoading(true)
        try {
            await repairDelete(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while deleting repair'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair deleted successfully'),
            life: 3000
        });
        setLoading(false)
        navigate("/repair/list");
    }

    const deleteAction = () => {
        confirmDialog({
            header: t('Delete Repair'),
            message: t("Are you sure you'd like to delete the repair?"),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: deleteRepair,
            reject: () => {
            }
        });
    }

    const markAsServiced = async (repair) => {
        setLoading(true)
        try {
            await repairMarkAsServiced(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as serviced'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('Repair updated successfully'), life: 3000
        });
        setLoading(false)
        reloadRepair()
    }


    const markAsServiceNull = async (repair) => {
        setLoading(true)
        try {
            await repairMarkServiceNull(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as serviced'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('Repair updated successfully'), life: 3000
        });
        setLoading(false)
        reloadRepair()
    }

    const markServiceFalse = async (repair) => {
        setLoading(true)
        try {
            await repairMarkServiceFalse(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as serviced'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('Repair updated successfully'), life: 3000
        });
        setLoading(false)
        reloadRepair()
    }

    const servicedAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to mark the repair as serviced?"),
            header: t('Serviced'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsServiced(repair)
            },
            reject: () => {
            }
        });
    }

    const unServicedAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to mark the repair as unserviced?"),
            header: t('Unserviced'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsServiceNull(repair)
            },
            reject: () => {
            }
        });
    }

    const unServicedByCustomerAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to mark the repair as unserviced?"),
            header: t('Unserviced'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markServiceFalse(repair)
            },
            reject: () => {
            }
        });
    }

    const markAsDelivered = async (repair) => {
        setLoading(true)
        try {
            await repairMarkAsDelivered(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as delivered'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('Repair updated successfully'), life: 3000
        });
        setLoading(false)
        reloadRepair()
    }

    const markAsNotDelivered = async (repair) => {
        setLoading(true)
        try {
            await repairMarkAsNotDelivered(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while marking repair as delivered'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('Repair updated successfully'), life: 3000
        });
        setLoading(false)
        reloadRepair()
    }

    const deliveredAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to mark the repair as delivered?"),
            header: t('Delivered'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsDelivered(repair)
            },
            reject: () => {
            }
        });
    }

    const unDeliveredAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to mark the repair as undelivered?"),
            header: t('Not Delivered'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                markAsNotDelivered(repair)
            },
            reject: () => {
            }
        });
    }

    const sendSMS = async (repair) => {
        setLoading(true)
        try {
            await repairSendSMS(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while sending SMS'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success', summary: t('Success'), detail: t('SMS has been sent successfully'), life: 3000
        });
        reloadRepair();
        setLoading(false)
    }
    const sendSMSAction = () => {
        confirmDialog({
            message: t("Are you sure you'd like to send SMS to the customer?"),
            header: t('Send SMS'),
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-primary',
            acceptLabel: t("Yes"),
            rejectLabel: t("No"),
            accept: () => {
                sendSMS(repair)
            },
            reject: () => {
            }
        });
    }

    const repairOptions = [
        {
            label: 'Ο πελάτης δεν επιθυμεί να επισκευαστεί',
            icon: <IoThumbsDownSharp />,
            command: unServicedByCustomerAction
        },
    ];

    return <div className={"action-buttons flex items-center bg-white p-5 rounded-md w-1/2  gap-5 "}>
        <Button className={"rounded-md delete-button p-3 border-0 aspect-square"} onClick={deleteAction}
                tooltipOptions={{position: 'top'}} tooltip={t("Delete Repair")}>
            <DeleteIcon/>
        </Button>
        <Button className={"rounded-md action-button p-3 border-0 aspect-square"} tooltipOptions={{position: 'top'}}
                tooltip={t("Send SMS")} onClick={sendSMSAction}>
            <SendSMSIcon/>
        </Button>
        <Button className={"rounded-md action-button p-3 border-0 aspect-square"} tooltipOptions={{position: 'top'}}
                tooltip={t("Reprint Repair Delivery Receipt")} onClick={() => {
            window.location.href = `/repair/${repair.id}/delivery-receipt`
        }}>
            <PrintIcon/>
        </Button>
        {repair && repair.serviced === null ?
            <>
                <Button className={"rounded-md deliver-button p-3 border-0 aspect-square"}
                        tooltipOptions={{position: 'top'}}
                        tooltip={t("Deliver Repair")}><DeliverIcon/></Button>
                <SplitButton onClick={servicedAction} tooltipOptions={{position: 'top'}}
                        tooltip={t("Mark as serviced")} model={repairOptions}
                        className={"serviced-button rounded-md "} icon={<ServiceIcon/>}
                             label={t("Serviced")}

                >

                </SplitButton>
            </> :
            <>
                <Button onClick={unServicedAction} tooltipOptions={{position: 'top'}}
                        tooltip={t("Mark as unserviced")}
                        className={"rounded-md action-button unserviced-button p-3 border-0 aspect-square"}
                >
                    <UnServiceIcon/>
                </Button>
                {repair && !repair.delivered ?
                    <Button onClick={deliveredAction} tooltipOptions={{position: 'top'}}
                            tooltip={t("Deliver Repair")}
                            className={"rounded-md deliver-button w-full flex p-3 items-center gap-5 text-lg justify-center border-0"}
                    >
                        <DeliverIcon/>
                        <span>{t("Deliver Repair")}</span>
                    </Button>
                    :
                    <Button className={"rounded-md serviced-button  w-full flex p-3 items-center gap-5 text-lg justify-center border-0"} tooltipOptions={{position: 'top'}}
                            tooltip={t("Mark as not delivered yet")} onClick={unDeliveredAction}>
                        <IoMdClose size={30}/>
                        <span>{t("Undelivered")}</span>
                    </Button>
                }
            </>
        }
    </div>
}

export default RepairActions
