import React, {useImperativeHandle, useRef} from "react";
import styles from './Button.module.css';

const Button = React.forwardRef((params, ref) => {

    // exposing the button's value to the parents ref.
    const buttonRef = useRef();

    function getValue() {
        return buttonRef.current.value
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: getValue
        }
    })

    return (
        <button className={`${styles.button} ${params.variation == ButtonVariation.WHITE ? styles.white : ""}`}
                onClick={params.onClick}>
            {params.text}
        </button>
    );
})

const ButtonVariation = {
    WHITE: "white",
    GREEN: "green"
}

export {Button, ButtonVariation}
