import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {PlusIcon} from "primereact/icons/plus";
import {AiFillEdit} from "react-icons/ai";
import {TrashIcon} from "primereact/icons/trash";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {useTranslation} from "react-i18next";
import {repairDelete, repairsGetList, repairsSearch} from "../../services/RepairService";
import {Tag} from "primereact/tag";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {InputText} from "primereact/inputtext";
import {Link, useNavigate} from "react-router-dom";
import {GrPrint} from "react-icons/gr";
import {userGetDetails} from "../../services/UserService";
import "./RepairList.css";
import ThumbnailImageWithAuth from "../../components/ThumbnailImageWithAuth";
import formatDate from "../../utils/formatDate";
import {SelectButton} from "primereact/selectbutton";
import formatPrice from "../../utils/formatPrice";
import RepairListLayout from "./parts/RepairListLayout";
import LayoutSelector from "./parts/LayoutSelector/LayoutSelector";
import RepairGridLayout from "./parts/RepairGridLayout";

export default function RepairList(props) {

    const toast = useRef(null);

    const [repairs, setRepairs] = useState([]);
    const [searchQuery, setSearchQuery] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [size, setSize] = useState(50);
    const [first, setFirst] = useState(0);
    const [loading, setLoading] = useState(true);
    const [storeConfiguration, setStoreConfiguration] = useState(false);
    const [reloadRepairs, setReloadRepairs] = useState(false);
    const {t, i18n} = useTranslation();
    const [layout, setLayout] = useState("default");
    const [replaceOrAddToRepairs, setReplaceOrAddToRepairs] = useState("replace");

    const getLayoutFromLocalStorage = () => {
        const repairListLayout = localStorage.getItem("repairListLayout")
        if (repairListLayout === "" || repairListLayout == null) {
            return "default";
        }
        return repairListLayout;
    }
    const setLayoutToLocalStorage = (value) => {
        localStorage.setItem("repairListLayout", value)
        setLayout(value)
    }

    const fetchRepairs = async () => {
        setLoading(true);
        try {
            let repairsResult = [];
            if (searchQuery != null && searchQuery.length > 3) {
                repairsResult = await repairsSearch(searchQuery);
                setRepairs(repairsResult);
                setTotalRecords(repairsResult.length);
            } else {
                repairsResult = await repairsGetList(page, getSortOrderForCall());
                if(replaceOrAddToRepairs === "replace"){
                    setRepairs(repairsResult.serviceItems)
                    setTotalRecords(repairsResult.pagesTotalNumber * repairsResult.serviceItems.length);
                }else{
                    const tmpRepairs = repairs
                    repairsResult.serviceItems.map((r)=>tmpRepairs.push(r))
                    setRepairs(tmpRepairs)
                }
            }
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while loading repairs'),
                life: 3000,
                position: "bottom"
            });
        } finally {
            setLoading(false);  // Ensure loading is set to false, regardless of success or error
        }
    };


    useEffect(() => {
        fetchRepairs();
    }, [page, sortOrder, sortField, size, searchQuery, reloadRepairs]);

    useEffect(() => {
        const fetchStoreConfiguration = async () => {
            try {
                const userDetails = await userGetDetails()
                setStoreConfiguration(userDetails.store.configuration)
            } catch (error) {
                setLoading(false)
                alert('Failed to fetch user data:', error);
            }
        }

        setLayout(getLayoutFromLocalStorage())
        fetchStoreConfiguration();
    }, []);

    const getSortOrderForCall = () => {
        if (sortOrder == null) {
            return "NEW"
        }
        return sortOrder === -1 ? "NEW" : "OLD";
    }

    const deleteRepair = async (repair) => {
        setLoading(true)
        try {
            await repairDelete(repair.id);
        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t('Something went wrong'),
                detail: t('Something went wrong while deleting repair'),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }
        toast.current.show({
            severity: 'success',
            summary: t('Success'),
            detail: t('Repair deleted successfully'),
            life: 3000
        });
        setReloadRepairs(!reloadRepairs)
    }

    const onPage = (e) => {
        setReplaceOrAddToRepairs("replace");
        setPage(e.page);
        setFirst(e.first);
        setSize(e.rows);
    }

    const onSort = (e) => {
        setReplaceOrAddToRepairs("replace");
        setSortOrder(e.sortOrder)
        setSortField(e.sortField)
    }

    const loadNextPage = (e) => {
        setReplaceOrAddToRepairs("add");
        setPage(page+1)
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <ConfirmDialog/>
        <div className={"absolute top-5 right-10"}>
            <LayoutSelector layout={layout} setLayout={setLayoutToLocalStorage}  loading={loading}/>
        </div>

        {layout === "grid" ?
            <RepairGridLayout repairs={repairs} loadNextPage={loadNextPage} loading={loading} />
            :
            <RepairListLayout repairs={repairs} deleteRepair={deleteRepair} searchQuery={searchQuery}
                              setSearchQuery={setSearchQuery} setLoading={setLoading} loading={loading}
                              sortOrder={sortOrder} size={size} totalRecords={totalRecords} sortField={sortField}
                              storeConfiguration={storeConfiguration} first={first} onPage={onPage} onSort={onSort}/>
        }
    </>;
}
