import {Panel} from "primereact/panel";
import React, {useRef, useState} from "react";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {BreadCrumb} from "primereact/breadcrumb";
import {HiHome} from "react-icons/hi";
import NavbarLink from "../../components/NavbarLink/NavbarLink";
import {urlContains} from "../../utils/urlContains";
import {MdLogout} from "react-icons/md";
import {IoReceiptOutline} from "react-icons/io5";
import {ReactComponent as LogoSvg} from "../../assets/LogoGreen.svg";
import navbarStyles from "./../../components/NavBar/Navbar.module.css";
import layoutStyles from "./../../layouts/MainLayout/MainLayout.module.css";
import {InputTextarea} from "primereact/inputtextarea";
import FormControl from "../../components/UI/FormControl";
import {callApiWithAuthentication} from "../../utils/callApiWithAuthentication";
import {Button} from "primereact/button";

function Redirect(props) {
    return null;
}

export default function CloudPrintingTest(props) {

    const toast = useRef(null);
    const [textToPrint, setTextToPrint] = useState(null);
    const [loading, setLoading] = useState(false);

    const logout = () => {
        localStorage.setItem("accessToken", null)
        localStorage.setItem("refreshToken", null)
        window.location.href = "/login"
    }

    const formSubmit = async (e) => {
        e.preventDefault()

        try {
            setLoading(true)
            const response =  await callApiWithAuthentication({
                method: "post",
                url: `${window.API_URL}/dinerly/print`,
                data: {
                    "printableText": textToPrint
                }
            })

            setLoading(false)
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Print completed successfully',
                life: 3000
            });
        } catch (error) {
            setLoading(false)

            toast.current.show({
                severity: 'warn',
                summary: 'Something went wrong',
                detail: 'Connection to the printer could not be established',
                life: 3000,
                position: "bottom"
            });
            throw error;
        }
    }

    return <div className={"w-full flex"}>
        <div className={navbarStyles.navbar}>
            <div>
                <div className={navbarStyles.logo}>
                    <LogoSvg/>
                </div>

                <div className={navbarStyles.linkList}>
                    <span className={"font-bold block mb-4"}>MENU</span>
                    <NavbarLink
                        linkTo="/print-test/"
                        icon={<IoReceiptOutline color={"#7C7C7C"}/>}
                        iconActive={<IoReceiptOutline color={"--var(green)"}/>}
                        label="Cloud Printing Test"
                        active={urlContains("printing-test")}
                    />
                </div>
            </div>

            <button className={"flex items-center gap-4 cursor-pointer p-2"} onClick={logout}>
                <MdLogout size={25} color={"#7C7C7C"}/>
                <span className={navbarStyles.label}>Log out</span>
            </button>
        </div>
        <div className={`bg-zinc-200 h-screen overflow-auto ${layoutStyles.mainContainer}`}>
            <div className={"p-10"}>
                <BreadCrumb className={"mb-10 bg-transparent p-0"} model={props.breadcrumb}
                            home={{icon: <HiHome color={"#7C7C7C"}/>, url: "/"}}/>
                <Toast ref={toast} position="bottom-right"/>
                <ConfirmDialog/>
                <Panel header={"Cloud Printing Test"}>
                    <form onSubmit={(e) => {
                        return formSubmit(e)
                    }}>
                        <FormControl label={"Text to print"} required={true}>

                            <div className="card flex justify-content-center">
                                <InputTextarea value={textToPrint} className={"w-full"}
                                               onChange={(e) => setTextToPrint(e.target.value)}
                                               rows={5} cols={30}/>
                            </div>
                        </FormControl>
                        <div className={"flex justify-end"}>
                            <Button label="Send Print Command" loading={loading}/>
                        </div>
                    </form>
                </Panel>
            </div>
        </div>
    </div>;

}
