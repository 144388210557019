import {Panel} from "primereact/panel";
import FormControl from "../../components/UI/FormControl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import React, {useEffect, useRef, useState} from "react";
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import {Checkbox} from "primereact/checkbox";
import {useTranslation} from "react-i18next";
import {repairCreate} from "../../services/RepairService";
import {userGetDetails} from "../../services/UserService";
import {InputNumber} from "primereact/inputnumber";

export default function RepairCreate(props) {
    const toast = useRef(null);
    const [description, setDescription] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [customerTelephone, setCustomerTelephone] = useState(null);
    const [customerTelephone2, setCustomerTelephone2] = useState(null);
    const [type, setType] = useState(null);
    const [paid, setPaid] = useState(false);
    const [price, setPrice] = useState(false);
    const [payInAdvance, setPayInAdvance] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showPayInAdvance, setShowPayInAdvance] = useState(false);
    const [serialNumber, setSerialNumber] = useState(null);
    const {t, i18n} = useTranslation();
    const [storeConfiguration, setStoreConfiguration] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true)

            const userDetails = await userGetDetails()
            setStoreConfiguration(userDetails.store.configuration)

            setLoading(false)
        } catch (error) {
            setLoading(false)
            alert('Failed to fetch user data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log("payInAdvance: " + payInAdvance)
    }, [payInAdvance]);

    const formSubmit = async (e) => {
        e.preventDefault()

        setLoading(true)
        try {
            let payInAdvanceAmount = payInAdvance
            if (!showPayInAdvance) {
                payInAdvanceAmount = 0
            }

            const response = await repairCreate({
                "customerName": customerName,
                "customerTelephone": customerTelephone,
                "customerTelephone2": customerTelephone2,
                "description": description,
                "price": parseFloat(price),
                "payInAdvanceAmount": parseFloat(payInAdvanceAmount),
                "serialNumber": serialNumber,
                "type": type,
            });
            setLoading(false)

            if (storeConfiguration.adminPanelPrintingType === "a4") {
                window.open(`/repair/${response.id}/delivery-receipt`, '_blank').focus();
            } else if (storeConfiguration.adminPanelPrintingType === "a5") {
                window.open(`/repair/${response.id}/delivery-receipt-a5`, '_blank').focus();
            }

            window.location.href = "/repair/list"

        } catch (error) {
            toast.current.show({
                severity: 'warn',
                summary: t("Something went wrong"),
                detail: t("Something went wrong while creating new repair"),
                life: 3000,
                position: "bottom"
            });
            setLoading(false)
            return
        }

        window.location.href = "/repair/list"
    }

    return <>
        <Toast ref={toast} position="bottom-right"/>
        <form onSubmit={formSubmit}>
            <div className={"flex gap-10 mb-10"}>
                <div className={"flex gap-10 flex-col w-full"}>
                    <Panel header={t("Customer")} className={"w-full"}>
                        <FormControl label={t("Customer Name")} required={true}>
                            <div className="card flex justify-content-center">
                                <InputText placeholder={t("Customer Name")} className={"w-full"}
                                           onChange={(e) =>
                                               setCustomerName(e.target.value.replace(",", "."))}
                                />
                            </div>
                        </FormControl>
                        <div className={"flex gap-5"}>
                            <FormControl label={t("Phone Number Mobile")} required={true}>
                                <div className="card flex justify-content-center">
                                    <InputText placeholder={t("Phone Number Mobile")} keyfilter="int"
                                               className={"w-full"}
                                               onChange={(e) =>
                                                   setCustomerTelephone(e.target.value.replace(",", "."))}
                                    />
                                </div>
                            </FormControl>
                            <FormControl label={t("Phone Number Landline")} required={true}>
                                <div className="card flex justify-content-center">
                                    <InputText placeholder={t("Phone Number Landline")} keyfilter="int"
                                               className={"w-full"}
                                               onChange={(e) =>
                                                   setCustomerTelephone2(e.target.value.replace(",", "."))}
                                    />
                                </div>
                            </FormControl>
                        </div>
                    </Panel>
                    <Panel header={t("Pricing")} className={"w-full"}>
                        <div className={"flex gap-5"}>
                            <FormControl label={t("Repair amount")}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Repair amount")} keyfilter="money"
                                               onChange={(e) => {
                                                   setPrice(e.target.value.replace(",", "."))
                                               }}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                            <FormControl label={t("Pay In Advance Amount / Inspection Cost")}
                                         className={payInAdvance === 0 && "invisible"}>
                                <div className="p-inputgroup flex-1 ">
                                    <InputText placeholder={t("Pay In Advance Amount / Inspection Cost")}
                                               keyfilter="money" value={payInAdvance}
                                               onChange={(e) => {
                                                   setPayInAdvance(e.target.value.replace(",", "."))
                                               }}
                                    />
                                    <span className="p-inputgroup-addon">€</span>
                                </div>
                            </FormControl>
                        </div>

                        <div className="flex items-center mb-4 gap-10">
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="payinadvance" onChange={e => {
                                    setShowPayInAdvance(e.checked)
                                    if (e.checked) {
                                        setPaid(false)
                                        setPayInAdvance(null)
                                    } else {
                                        setPaid(false)
                                        setPayInAdvance(0)
                                    }
                                }}
                                          checked={showPayInAdvance}></Checkbox>
                                <label htmlFor="payinadvance"
                                       className="cursor-pointer text-base text-gray-500">{t("Pay In Advance / Inspection Cost")}</label>
                            </div>
                            <div className="flex items-center gap-2">
                                <Checkbox inputId="paid" onChange={e => {
                                    setPaid(e.checked)
                                    if (e.checked) {
                                        setShowPayInAdvance(false)
                                        setPayInAdvance(price != null ? price : 1)
                                    } else {
                                        setShowPayInAdvance(false)
                                        setPayInAdvance(0)
                                    }
                                }}
                                          checked={paid}></Checkbox>
                                <label htmlFor="paid"
                                       className="cursor-pointer text-base text-gray-500">{t("Paid")}</label>
                            </div>
                        </div>
                    </Panel>
                </div>

                <Panel header={t("Repair")} className={"w-full"}>
                    <FormControl label={t("Repair Type")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Repair Type")} className={"w-full"}
                                       onChange={(e) =>
                                           setType(e.target.value)}
                            />
                        </div>
                    </FormControl>
                    <FormControl label={t("Serial Number")}>
                        <div className="card flex justify-content-center">
                            <InputText placeholder={t("Serial Number")} className={"w-full"}
                                       onChange={(e) =>
                                           setSerialNumber(e.target.value)}
                            />
                        </div>
                    </FormControl>
                    <FormControl label={t("Description")} required={true}>
                        <div className="card flex justify-content-center">
                            <InputTextarea value={description} className={"w-full"} placeholder={t("Description")}
                                           onChange={(e) => setDescription(e.target.value)}
                                           rows={12} cols={50}/>
                        </div>
                    </FormControl>
                </Panel>
            </div>
            <div className={"flex justify-end"}>
                <Button label={t("Create Repair")} loading={loading} disabled={loading}/>
            </div>
        </form>
    </>
}
